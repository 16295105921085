import React from 'react'
import { connectHierarchicalMenu } from 'react-instantsearch-dom'
import tw, { css, styled } from 'twin.macro'

import { ChevronDown, ChevronRight } from 'react-feather'

const Tree = styled.ul`
	${tw`font-normal text-base ml-2 p-0 list-none`}
	li {
		${tw`select-none`}
		a {
			${tw`flex items-center pb-1 mb-2`}
			span {
				${tw`mb-0`}
			}
		}
	}
`
const Count = tw.span`rounded text-xs font-semibold bg-gray-100 text-gray-500 py-0 px-1 ml-2 leading-4`
const Label = tw.span`text-sm font-light text-gray-800`
const Point = tw.span`text-gray-600`

const CategoryTree = ({ items, refine, createURL, role }) => {
	return (
		<Tree role={role ? role : 'tree'} aria-labelledby="category-tree">
			{items.map((item, key) => (
				<li role="none" key={key}>
					<a
						role="treeitem"
						aria-expanded={item.isRefined ? true : false}
						href={createURL(item.value)}
						onClick={event => {
							event.preventDefault()
							refine(item.value)
						}}
						onKeyDown={event => {
							event.preventDefault()
							refine(item.value)
						}}
					>
						<Point aria-hidden>
							{item.isRefined ? (
								<ChevronDown css={tw`h-4 w-4 stroke-current`} />
							) : (
								<ChevronRight css={tw`h-4 w-4 stroke-current`} />
							)}
						</Point>
						<Label css={item.isRefined && tw`font-bold`}>{item.label}</Label>
						<Count aria-hidden>{item.count}</Count>
					</a>
					{item.items && (
						<CategoryTree
							role="group"
							items={item.items}
							refine={refine}
							createURL={createURL}
						/>
					)}
				</li>
			))}
		</Tree>
	)
}

export default connectHierarchicalMenu(CategoryTree)
