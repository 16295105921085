import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connectHits } from 'react-instantsearch-dom'
import Hit from './hit'

const Hits = ({ hits, locale }) => {
	return (
		<div className="ais-Hits">
			<ul className="ais-Hits-list">
				{hits.map((hit, index) => (
					<li key={index} className="ais-Hits-item">
						<Hit hit={{ ...hit, position: index + 1 }} locale={locale} />
					</li>
				))}
			</ul>
		</div>
	)
}

Hits.propTypes = {
	hits: PropTypes.array,
}

export default connectHits(Hits)
