import React from 'react'
import PropTypes from 'prop-types'
import { Highlight, Snippet } from 'react-instantsearch-dom'
import tw, { css, styled } from 'twin.macro'

import { navigate } from 'gatsby'
import { onProductClick, onAddToCart } from '../utils/analytics'
import useCart from '../../hooks/useCart'
import useTheme from '../../hooks/theme'
import localeCheck from '../utils/locale-check'
/** Styles */
const Article = styled.article`
	${tw`flex sb:flex-col p-2 text-gray-800 text-sm leading-5 w-full hover:bg-gray-200 rounded-md transform ease-in-out duration-300`}
	em {
		color: #e2a400;
		font-size: 11px;
		font-weight: 600;
	}
`
const ImageContainer = styled.header`
	${tw`flex items-center justify-center w-48 h-48`}
	${tw`mr-2 sb:m-auto`}
	@media (max-width: 900px) {
		flex: 1;
	}
	img {
		${tw`h-auto max-h-full max-w-full cursor-pointer`}
	}
`
const InfoContainer = styled.div`
	${tw`p-2`}
	@media (max-width: 900px) {
		flex: 2;
	}
	overflow-wrap: break-word;
	word-break: break-word;
	.category {
		${tw`text-gray-500 text-xs font-semibold leading-none mb-2 uppercase`}
	}
	h2 {
		${tw`text-base mb-2 text-gray-800 font-semibold`}
	}
	.description {
		${tw`mt-1`}
	}
	footer {
		${tw`flex flex-row flex-wrap items-center`}
		p {
			${tw`flex my-4`}
			.currency {
				${tw`inline-flex text-yellow-500 text-xs font-semibold pr-1`}
			}
		}
		.group {
			${tw`inline-flex mx-auto`}
			button {
				${tw`bg-yellow-500 hover:bg-yellow-400 text-gray-700 font-medium py-2 px-4 cursor-pointer`}
				&:first-of-type {
					${tw`rounded-l`}
				}
				&:last-of-type {
					${tw`rounded-r`}
				}
			}
		}
	}
`
export const Hit = ({ hit, locale }) => {
	const { addToCart } = useCart()
	const { openCart } = useTheme()
	const checkedLocale = localeCheck(locale, 'en-AU')
	const goToProduct = () => {
		onProductClick(
			{
				name: hit.name,
				sku: hit.sku,
				price: hit.price,
				brand: hit.brand,
				category: hit.categories.toString(),
				list: 'Category List',
				position: hit.__position - 1,
			},
			'Category List',
			hit.__position - 1,
			navigate(`${checkedLocale}/product/${hit.url}/`)
		)
	}
	return (
		<Article>
			<ImageContainer>
				<img
					tw="hover:cursor-pointer"
					onClick={goToProduct}
					onKeyDown={goToProduct}
					src={
						(hit.images ? hit.images[0].file.url : hit.image) +
						'?fm=png&fl=png8&w=300&h=300'
					}
					alt={hit.name}
				/>
			</ImageContainer>

			<InfoContainer>
				<p className="category">{hit.categories.toString()}</p>
				<h2>
					<Highlight attribute="name" tagName="mark" hit={hit} />
				</h2>
				<p className="description">
					<Snippet attribute="description" tagName="mark" hit={hit} />
				</p>

				<footer>
					<p>
						{/* <span className="currency">$</span>{' '} */}
						<strong>
							{hit.price * 1 >= 1
								? Number(hit.price * 1).toLocaleString('en-AU', {
										style: 'currency',
										currency: 'AUD',
								  })
								: ''}
						</strong>
					</p>
					<div className="group">
						{/* <button
							onClick={() => {
								addToCart(product)
								onAddToCart(product, 'Category List')
								openCart()
							}}
							onKeyDown={() => {
								addToCart(product)
								onAddToCart(product, 'Category List')
								openCart()
							}}
						>
							Buy
						</button> */}
						<button onClick={goToProduct} onKeyDown={goToProduct}>
							View Product
						</button>
					</div>
				</footer>
			</InfoContainer>
		</Article>
	)
}
Hit.propTypes = {
	hit: PropTypes.object,
}
export default Hit
