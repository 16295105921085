import React from 'react'
import { connectStats } from 'react-instantsearch-dom'

const ResultsNumberMobile = ({ nbHits }) => (
	<div>
		<strong>{Number(nbHits).toLocaleString('en-AU')}</strong> results
	</div>
)

export default connectStats(ResultsNumberMobile)
