import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Highlight, connectRefinementList } from 'react-instantsearch-dom'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import { Lottie } from '@crello/react-lottie'
import * as animationCheckbox from '../../../animations/checkbox.json'
import { Search } from 'react-feather'
import tw, { css, styled } from 'twin.macro'

const CheckboxContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`
const Count = tw.span`rounded text-xs font-semibold bg-gray-100 text-gray-500 py-0 px-1 ml-2 leading-4`
const Label = tw.label`flex items-center text-sm font-light text-gray-800 cursor-pointer`
const Input = tw.input`appearance-none bg-white border-gray-300 border rounded pr-2 py-2 text-base leading-5 flex w-full pl-10 sm:text-sm sm:leading-4`
const CheckBox = ({ isRefined }) => (
	<CheckboxContainer css={tw`w-6 h-6 cursor-pointer mx-2 bg-white rounded-sm`}>
		<CustomCheckboxInput checked={isRefined} type="checkbox" />
		<Lottie
			playingState={'playing'}
			speed={1}
			direction={isRefined ? 1 : -1}
			width={18}
			height={18}
			className="lottie-container checkbox"
			config={{
				animationData: animationCheckbox.default,
				loop: false,
				autoplay: true,
				renderer: 'svg',
			}}
			aria-hidden
		/>
	</CheckboxContainer>
)
function immutableMove(arr, from, to) {
	return arr.reduce((prev, current, idx, self) => {
		if (from === to) {
			prev.push(current)
		}
		if (idx === from) {
			return prev
		}
		if (from < to) {
			prev.push(current)
		}
		if (idx === to) {
			prev.push(self[from])
		}
		if (from > to) {
			prev.push(current)
		}
		return prev
	}, [])
}
const AttributeSelect = ({
	searchable,
	selectItem,
	items,
	isFromSearch,
	refine,
	searchForItems,
	createURL,
	currentRefinement,
}) => (
	<ul>
		{immutableMove(items, 0, 2).map(item => {
			if (item.label === 'small') {
				return (
					<li key={item.label}>
						<Label
							onClick={event => {
								event.preventDefault()
								refine(item.value)
								navigate(createURL(item.value))
							}}
						>
							<CheckBox isRefined={item.isRefined} />
							{'Small < 12m'}
							<Count>{item.count}</Count>
						</Label>
					</li>
				)
			}
			if (item.label === 'medium') {
				return (
					<li key={item.label}>
						<Label
							onClick={event => {
								event.preventDefault()
								refine(item.value)
								navigate(createURL(item.value))
							}}
						>
							<CheckBox isRefined={item.isRefined} />
							{'Medium < 15m'}
							<Count>{item.count}</Count>
						</Label>
					</li>
				)
			}
			if (item.label === 'large') {
				return (
					<li key={item.label}>
						<Label
							onClick={event => {
								event.preventDefault()
								refine(item.value)
								navigate(createURL(item.value))
							}}
						>
							<CheckBox isRefined={item.isRefined} />
							{'Large 15m+'}
							<Count>{item.count}</Count>
						</Label>
					</li>
				)
			}
			if (item.label === 'commercial') {
				return (
					<li key={item.label}>
						<Label
							onClick={event => {
								event.preventDefault()
								refine(item.value)
								navigate(createURL(item.value))
							}}
						>
							<CheckBox isRefined={item.isRefined} />
							{'Commercial'}
							<Count>{item.count}</Count>
						</Label>
					</li>
				)
			}
		})}
	</ul>
)

export default connectRefinementList(AttributeSelect)
