import React, { useState, useEffect } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import tw, { css, styled } from 'twin.macro'

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import './PriceSlider.css'

const StyledTicks = styled(Ticks)``

function Handle({
	domain: [min, max],
	handle: { id, value, percent },
	disabled,
	getHandleProps,
}) {
	return (
		<>
			{/* Dummy element to make the tooltip draggable */}
			<div
				css={{
					position: 'absolute',
					left: `${percent}%`,
					width: 40,
					height: 25,
					transform: 'translate(-50%, -100%)',
					cursor: disabled ? 'not-allowed' : 'grab',
					zIndex: 1,
				}}
				aria-hidden={true}
				{...getHandleProps(id)}
			/>
			<div
				role="slider"
				className="slider-handle"
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				css={{
					left: `${percent}%`,
					cursor: disabled ? 'not-allowed' : 'grab',
				}}
				{...getHandleProps(id)}
			/>
		</>
	)
}

const PriceSlider = ({ min, max, refine, currentRefinement, canRefine }) => {
	const domain = [min, max]

	const refs = [
		currentRefinement &&
			currentRefinement.min &&
			Number.isFinite(currentRefinement.min) &&
			currentRefinement.min,
		currentRefinement &&
			currentRefinement.max &&
			Number.isFinite(currentRefinement.max) &&
			currentRefinement.max,
	]

	const [ticksValues, setTicksValues] = useState(refs)

	const onChange = values => {
		if (Number.isFinite(values[0]) && Number.isFinite(values[1])) {
			refine({
				min: Number.isFinite(values[0]) && values[0],
				max: Number.isFinite(values[1]) && values[1],
			})
		}
	}

	useEffect(() => {
		if (Number.isFinite(refs[0]) && Number.isFinite(refs[1])) {
			setTicksValues(refs)
		}
	}, [currentRefinement])

	if (!canRefine) {
		return null
	}
	return (
		<Slider
			mode={2}
			step={1}
			domain={domain}
			values={refs}
			disabled={!canRefine}
			onChange={onChange}
			onUpdate={setTicksValues}
			rootcss={{ position: 'relative', marginTop: '1.5rem' }}
			className="ais-RangeSlider"
		>
			<Rail>
				{({ getRailProps }) => (
					<div className="slider-rail" {...getRailProps()} />
				)}
			</Rail>

			<Tracks left={false} right={false}>
				{({ tracks, getTrackProps }) => (
					<div>
						{tracks.map(({ id, source, target }) => (
							<div
								key={id}
								className="slider-track"
								css={{
									left: `${source.percent}%`,
									width: `${target.percent - source.percent}%`,
								}}
								{...getTrackProps()}
							/>
						))}
					</div>
				)}
			</Tracks>

			<Handles>
				{({ handles, getHandleProps }) => (
					<div>
						{handles.map(handle => (
							<Handle
								key={handle.id}
								handle={handle}
								domain={domain}
								getHandleProps={getHandleProps}
							/>
						))}
					</div>
				)}
			</Handles>

			<Ticks values={ticksValues}>
				{({ ticks }) => (
					<div>
						{[ticks[ticks.length - 2], ticks[ticks.length - 1]].map(
							({ id, count, value, percent }, index) =>
								Number.isFinite(value) &&
								value >= 1 &&
								(ticksValues[0] === value || ticksValues[1] === value) &&
								ticks.length - index <= 2 && (
									<div
										key={id}
										className={`slider-tick`}
										css={{
											marginLeft: `${-(100 / count) / 2}%`,
											width: `${100 / count}%`,
											left: `${percent <= 100 ? percent : 0}%`,
										}}
									>
										<span css={{ color: '#e2a400', marginRight: 4 }}>$</span>
										{Number(value).toLocaleString()}
									</div>
								)
						)}
					</div>
				)}
			</Ticks>
		</Slider>
	)
}

export default connectRange(PriceSlider)
